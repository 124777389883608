import {createReducer, createAsyncThunk, current} from "@reduxjs/toolkit";
import SignInService from "../../services/signInService";
import UsuarioService from "../../services/usuarioService";
import { getItem, setItem, removeItem } from "../../utility/localStorageControl";
import eventBusService from "../../services/eventBusService";
import { GET_LOGGED_USER } from "../../services/eventBusService";
import { loginKeyCloak } from "../../config/dataService";
import { jwtDecode } from "jwt-decode";

// este initialState se crea antes que el que modifico que el store pq este reducer lo importo alla. Entonces luego de importarlo, le modifico el initialState
  const initialState = {
      loading: "initial",
      isLoggedIn: false,
      idPersonalizado: getItem("pers") || false,
      errorMsge: "",
      permisos: {},
      logueado: "deslogueado",
      usuarioLS: {}
  };

  // esta fc se ejecuta antes que la que hay en el index.js y modifican initialState pq son funciones que se ejecutan antes de la creacion del store asique la parte del estado que modifican ya tendria cargada la informacion
  function cargarLocalStorage() {
    try {
      const serialisedState = getItem("loggedIn");
      if (serialisedState === null) {
        return undefined;
      } else {
        const usuarioLocalStorage = getItem("usuario");
        if(usuarioLocalStorage) {
          initialState.usuarioLS = usuarioLocalStorage;
          initialState.logueado = "logueado";
          initialState.loading = 'fulfilled'
          initialState.isLoggedIn = JSON.stringify(serialisedState);
          eventBusService.dispatch(GET_LOGGED_USER);
        return initialState;
        } else {
          console.log("else");
        }
      }
    } catch (e) {
      console.log(e);
      return undefined;
    }
  };

  export const usuarioLocalStorage = createAsyncThunk(
    "autentication/usuarioLocalStorage",
    async () => {
        try {
          //const response = await desencriptarObjeto(getItem("usuario"));
          const response = getItem("usuario");
          return response;
        } catch (error) {
          console.log(error);
        }
  });
  
  // para cuando el usuario ya esta logueado
   cargarLocalStorage();

  export const singIn = createAsyncThunk(
    "autentication/loguear",
    async (values, {rejectWithValue}) => {
        try {
          const keycloakLog = await loginKeyCloak(values);
          console.log(keycloakLog);
          
          const decoded = jwtDecode(keycloakLog.data.access_token);
          const service = new UsuarioService();
          let responseKeycloak = await service.getKeycloakUsuario(decoded.sub);
          responseKeycloak.access_token = keycloakLog.data.access_token;
          responseKeycloak.refresh_token = keycloakLog.data.refresh_token;
          return responseKeycloak;
        } catch (error) {
          console.log(error);
          
          return rejectWithValue(error.response.data);
        }
  });

const autenticacionReducer = createReducer(initialState, (builder) => {
    builder.addCase(singIn.pending, (state, action) => {
        if (state.loading === 'initial') {
          state.loading = 'pending'
        }
      });

    builder.addCase(singIn.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
          //state.loading = 'fulfilled';
          //state.isLoggedIn = true;
          state.errorMsge = "";
          state.logueado = "logueado";
          //setItem("loggedIn", JSON.stringify(action.payload));
          console.log("action", action);
          setItem("loggedIn", {
            access: action.payload.access_token,
            refresh: action.payload.refresh_token,
            user_id: action.payload.id
          });
          eventBusService.dispatch(GET_LOGGED_USER);
        }
      });

    builder.addCase(singIn.rejected, (state, action) => {
      console.log(action);
      
        if (state.loading === 'pending') {
          state.loading = 'rejected';
          state.errorMsge = action.payload.error_description === "Account disabled" ? "Cliente bloqueado. Por favor, comuiníquese con su agente." : "Credenciales inválidas";
          state.logueado = "deslogueado";
        }
      });

    builder.addCase("autentication/desloguear", (state, action) => {
      //console.log(current(state));
      state.loading = 'initial'
      state.idPersonalizado = false;
      state.isLoggedIn = false;
      state.permisos = {};
      state.logueado = "deslogueado";
      state.usuarioLS = {};
      removeItem("loggedIn");
      removeItem("usuario");
      removeItem("pers");
      //eventBusService.dispatch(EXPIRED_SESSION_EVENT, () => errorGlobal("", "Tu sesión ha expirado.", "Vuelva a ingresar nuevamente."));
      // el q estaba en dataService
      // eventBusService.dispatch(EXPIRED_SESSION_EVENT, error.response.data.code);
    });

    builder.addCase("autentication/personalizar", (state, action) => {
      const {id, nombre, cnv_categ, cod_op} = action.payload;
      state.idPersonalizado = { id, nombre, cnv_categ, cod_op };
      setItem("pers", { id, nombre, cnv_categ, cod_op });
    });

    builder.addCase("autentication/despersonalizar", (state, action) => {
      state.idPersonalizado = false;
      removeItem("pers");
    });

    builder.addCase("autentication/resetPass", (state, action) => {
      state.loading = 'resetPass';
    });

    builder.addCase("autentication/loguearFinal", (state, action) => {
      state.isLoggedIn = JSON.stringify(getItem("loggedIn"));
      state.usuarioLS = getItem("usuario");
      state.loading = 'fulfilled';
    });

    builder.addCase("autentication/tipoUsuario", (state, action) => {
      switch (action.payload.tipo) {
        case "difusionSgr":
          state.permisos.difusionSgr = true;
          break;
      
          case "operador":
            state.permisos.operador = true;
            break;

          case "carteraLimitada":
            state.permisos.carteraLimitada = true;
            break;

          case "caja":
            state.permisos.caja = true;
            break;

          case "difusionCpd":
            state.permisos.difusionCpd = true;
            break;

        default:
          break;
      }
    });
});

export default autenticacionReducer;