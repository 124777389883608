// declare here all the shared functions
import {emailRegex} from "./constants";
import dayjs from "dayjs"
import { getItem } from "./localStorageControl";
import axios from "axios";

export const parseAmount = cents => (cents / 100).toLocaleString("es-AR", { style: "currency", currency: "ARS" });
export const parseAmountNoMoney = cents => {
    const data = (cents / 100).toLocaleString("es-AR", { style: "currency", currency: "ARS" });
    return data.replace('$' , '');
}

/*
            const formatNumber = new Intl.NumberFormat(locale, {style: "currency", currency: "ARS"}).format(value);
            return formatNumber.replace('$' , '')

*/

// export const formatDate = timestamp => Intl.DateTimeFormat('es-AR', {
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit'
// }).format(timestamp);

export const formatDate = timestamp => {
    if (!timestamp) {
        return '-';
    }

    return Intl.DateTimeFormat('es-AR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).format(timestamp);
};

export const singular = (palabra) => {
    let final;
    if(palabra.slice(-1) === "s") {
        final = palabra.substring(0,  palabra.length - 1);
        return final;
    } else {
        return palabra;
    }
};

export const fechaConAnioAlFinal = date => dayjs(date).format('DD-MM-YYYY');

export const formatCompleteDate = date => date.substring(date.length-5, date.length) + ' - ' + Intl.DateTimeFormat('es-AR').format(new Date(`${date.substring(0,4)}-${date.substring(5,7)}-${date.substring(8,10)}`)).toString();

export const formatDateSpecial = timestamp => Intl.DateTimeFormat('es-AR').format(new Date(`${timestamp.substring(0,4)}-${timestamp.substring(4,6)}-${timestamp.substring(6,8)}`).getTime());

export const formatDateText = timestamp =>
    Intl.DateTimeFormat('es-AR', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    }).format(timestamp)

export const formatTime = timestamp =>
    Intl.DateTimeFormat('es-AR', {
        hour: '2-digit',
        minute: '2-digit'
    }).format(timestamp)

export const formatDateFull = timestamp =>
    Intl.DateTimeFormat('es-AR', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).format(timestamp)

export const formatCuit = cuit => cuit ? `${cuit.substring(0,2)}-${cuit.substring(2,10)}-${cuit.substring(10,11)}` : "-";

export const verifyCuit = (cuit) => {

    if ((cuit.length === 11 && cuit.indexOf('-') > -1) || (cuit.toString().length !== 13 && cuit.toString().indexOf('-') > -1))
        return 0;

    let result = 0;
    const cuitNumber = removeCuitFormat(cuit);
    const codes = "6789456789";
    const verifierNumber = parseInt(cuitNumber[cuitNumber.length - 1]);
    let x = 0;

    while (x < 10) {
        let digitoValidador = parseInt(codes.substring(x, x + 1));
        if (isNaN(digitoValidador)) digitoValidador = 0;

        let digito = parseInt(cuitNumber.substring(x, x + 1));
        if (isNaN(digito)) digito = 0;

        let digitoValidacion = digitoValidador * digito;
        result += digitoValidacion;
        x++;
    }

    return result % 11 === verifierNumber;
};

export const boolATexto = value => value ? 'SI' : 'NO';

export const removeCuitFormat = cuit => String(cuit).replace(/-/g, "");

export const validateEmail = value => emailRegex.test(value);

export const generarClave = () => {
    try {
      // Genera una clave usando el algoritmo AES-GCM con una longitud de clave de 256 bits
      const clave = crypto.subtle.generateKey(
        { name: 'AES-GCM', length: 256 },
        true, // indica que la clave puede ser utilizada para cifrado y descifrado
        ['encrypt', 'decrypt'] // operaciones permitidas con la clave
      );
      return clave;
    } catch (error) {
      console.error('Error al generar la clave:', error);
    }
};

async function convertirClave(cadena) {
    // Convierte la cadena de texto a un ArrayBuffer
    const claveBuffer = new TextEncoder().encode(cadena);
  
    // Deriva la clave utilizando la SubtleCrypto API con el mismo algoritmo que usarás en el cifrado
    const claveDerivada = await crypto.subtle.importKey(
      'raw',
      claveBuffer,
      { name: 'PBKDF2' },
      false,
      ['deriveKey']
    );
  
    // Deriva una clave específica para el algoritmo de cifrado (AES-GCM en este caso)
    const claveCifrado = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: new Uint8Array([]),
        iterations: 100000,
        hash: 'SHA-256',
      },
      claveDerivada,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );
  
    return claveCifrado;
  }



// Función para encriptar el objeto
export const encriptarObjeto = async (objeto) => {
    // Convierte el objeto a una cadena JSON
    const objetoJson = JSON.stringify(objeto);
    const encodedText = new TextEncoder().encode(objetoJson);
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Vector de inicialización

    //const clave = getItem("loggedIn").access;
    const clave = await convertirClave(getItem("loggedIn").access);

    const cifrado = await crypto.subtle.encrypt({ name: 'AES-GCM', iv }, clave, encodedText);
    
    // Combina el vector de inicialización con el texto cifrado
    const cifradoArray = new Uint8Array(iv.length + new Uint8Array(cifrado).length);
    cifradoArray.set(iv);
    cifradoArray.set(new Uint8Array(cifrado), iv.length);
    //const conviertoAHexadecimal = Array.from(cifradoArray, byte => ('0' + (byte & 0xFF).toString(16)).slice(-2)).join('');
    return cifradoArray;
};

// Función para desencriptar el objeto
export const desencriptarObjeto = async (objetoEncriptado) => {
    try {
        const arrayDesdeObjeto = Object.keys(objetoEncriptado).map(key => objetoEncriptado[key]);
        const iv = arrayDesdeObjeto.slice(0, 12); // Extrae el vector de inicialización
        const cifrado = arrayDesdeObjeto.slice(12);
        const clave = await convertirClave(getItem("loggedIn").access);

        // Convierte el vector de inicialización a un ArrayBuffer
        const ivBuffer = new Uint8Array(iv).buffer;

        // Convierte el cifrado a ArrayBuffer utilizando TextEncoder o Uint8Array
        const cifradoArrayBuffer = new Uint8Array(cifrado).buffer;

        // Realiza la desencriptación de forma asincrónica y espera a que se resuelva la promesa
        const decryptedArray = await crypto.subtle.decrypt(
            { name: 'AES-GCM', iv: ivBuffer },
            clave,
            cifradoArrayBuffer
        );

        // Convierte el resultado a texto utilizando TextDecoder
        const decryptedText = new TextDecoder().decode(decryptedArray);

        // Parsea el resultado como JSON
        return JSON.parse(decryptedText);
    } catch (error) {
        console.error("Error al desencriptar el objeto:", error);
        throw error;
    }
};

export const importArchivos = async (url, method, formDataName, savedFile, setErrores, errores, baseURL = `https://staging.api.mav-cpd.com.ar`) => {
    // let copiaSavedFile = new File("asd", savedFile, {
    //     type: "application/x-7z-compressed"
    // });
    //savedFile.type = "application/x-7z-compressed"
    console.log(savedFile);
    //console.log(copiaSavedFile);
    
    // if(savedFile.type === "" && baseURL.includes("bcra")) {
    //     //delete copiaSavedFile.type
    //     copiaSavedFile = {...copiaSavedFile, type: "application/x-7z-compressed"};
    // }
    const formData = new FormData();
    formData.append(formDataName, savedFile);
    try {
        await axios({
            baseURL,
            url,
            method,
            data: formData,
            headers: {
                'Authorization': getItem("loggedIn") && getItem("loggedIn").access ? `Bearer ${getItem("loggedIn").access}` : null,
            },
        });
    } catch (error) {
        console.log(error);
        throw error.response
    };
};
//'Content-Type': 'multipart/form-data'